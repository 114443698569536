<template>
  <div class="px-[2px] bg-status-danger-base rounded-md">
    <div class="px-[2px] bg-status-warn-base rounded-md">
      <div class="px-[2px] bg-status-success-base rounded-md">
        <div class="p-[2px] bg-white rounded-md">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
